import * as React from "react"
import FullScreenBackground from "../components/FullScreenBackground"
import bg from "../images/homeBg.png"
import "./comingSoon.scss"
const CompingSoon = ({ unlock, ...props }) => {
  const [password, setPassword] = React.useState(false)
  const [error, setError] = React.useState(false)

  return (
    <div id="coming-soon">
      <FullScreenBackground src={bg} alt={"Fabricsthan"} />
      <div className="container">
        <div>
          <p id="fabricsthan">Fabricsthan</p>
          <p>is coming soon...</p>

          <form>
            <p>Enter password to see the preview</p>
            <label htmlFor="password">Password</label>
            <fieldset>
              <input
                id="password"
                type="password"
                onChange={(e) => {
                  setPassword(e.target.value)
                  if (error) {
                    setError(false)
                  }
                }}
              />
            </fieldset>
            <button
              onClick={(e) => {
                e.preventDefault()
                unlock(password).then((result) =>
                  result === false ? setError(true) : null
                )
              }}
            >
              Submit
            </button>

            {error && <p className="error">Incorrect password. Try again.</p>}
          </form>
        </div>
      </div>
    </div>
  )
}

export default CompingSoon
